<script lang="ts" setup>
import { diff } from 'deep-object-diff'
import pointer from 'json-pointer'
import { cloneFnJSON } from '@vueuse/core'
import lzString from 'lz-string'
import { countFilters, transformFiltersIntoPrismaFilters } from './utils'
import { EMPTY_FILTER_GROUP } from './defaults'
import type { FilterConfig } from './configs'
import type { FilterGroup } from './schema'
import type { FilterTemplateGroup } from '~/types'

const props = defineProps<{ filterConfig: FilterConfig, filterGroup: FilterTemplateGroup, disabledQueryStore?: boolean, initialFilter?: any }>()
const emit = defineEmits<{
  (e: 'update', entity: any): void
  (e: 'filterDetails', entity: FilterGroup | undefined): void
}>()
const { compressToBase64, decompressFromBase64 } = lzString
const { $i18n } = useNuxtApp()
const { canUse } = useAuthorization()
const { filterTemplateQuery } = useQuery()
const { openFilterTemplatePopup } = useOpeners()
const route = useRoute()
const router = useRouter()

const selectedTemplateId = ref<string | null>()
const { data: filterTemplatesData } = filterTemplateQuery.findMany({ where: { group: props.filterGroup } })
const filterTemplates = computed(() => filterTemplatesData.value)
const templateOptions = computed(() => filterTemplates.value?.map(t => ({ value: t.id, label: t.name })))

const initialFilter = route.query.filters && !props.disabledQueryStore ? JSON.parse(decompressFromBase64(route.query.filters as string)) : props.initialFilter ?? cloneFnJSON(EMPTY_FILTER_GROUP)
const filter: Ref<FilterGroup> = ref(initialFilter)
const isFilterEmpty = computed(() => Object.keys(diff(filter.value, EMPTY_FILTER_GROUP)).length === 0)
const filterEmitted: Ref<FilterGroup> = ref(initialFilter)
const isFilterEmitted = computed(() => Object.keys(diff(filter.value, filterEmitted.value)).length === 0)

const emitFilter = () => {
  try {
    const filterClone = cloneFnJSON(filter.value)
    const encodedFilter = compressToBase64(JSON.stringify(filterClone))
    if (encodedFilter.length > 1700) {
      throw new Error($i18n.t('filters.error.tooLong'))
    }

    emit('update', isFilterEmpty.value ? undefined : transformFiltersIntoPrismaFilters(props.filterConfig, filterClone))
    filterEmitted.value = filterClone
    emit('filterDetails', isFilterEmpty.value ? undefined : filterEmitted.value)
    if (!props.disabledQueryStore) {
      if (isFilterEmpty.value) {
        router.push({ query: { ...route.query, filters: undefined } })
        return
      }
      router.push({ query: { ...route.query, filters: encodedFilter } })
    }
  } catch (error) {
    console.error(`Error: ${error}`)
    notify('error', $i18n.t('filters.error', { error }))
  }
}
const applyFilterTemplate = () => {
  const selectedTemplate = filterTemplates.value?.find(t => t.id === selectedTemplateId.value)?.base64
  if (!selectedTemplate) {
    throw new Error($i18n.t('filters.error.templateNotFound'))
  }
  filter.value = JSON.parse(decompressFromBase64(selectedTemplate))
  emitFilter()
}
const saveFilterTemplate = () => {
  const filterClone = cloneFnJSON(filter.value)
  const encodedFilter = compressToBase64(JSON.stringify(filterClone))
  if (encodedFilter.length > 1700) {
    throw new Error($i18n.t('filters.error.tooLong'))
  }

  openFilterTemplatePopup.open({ mode: 'create', data: { group: props.filterGroup, base64: encodedFilter } })
}
const resetFilters = () => {
  filter.value = cloneFnJSON(EMPTY_FILTER_GROUP)
  emitFilter()
}
const setFilterValue = (filterPath: string, value: any) => {
  pointer.set(filter.value, filterPath, cloneFnJSON(value))
}
const unsetFilterValue = (filterPath: string) => {
  pointer.remove(filter.value, filterPath)
}
emitFilter()

const { $trpc, queryClient, useMutation, makeTrpcErrorToast, makeTrpcSuccessToast } = useMutationHelpers()
const filterTemplateToDelete = ref<string | undefined>(undefined)
const deleteFilterTemplate = useMutation({
  mutationFn: $trpc.filterTemplates.deleteFilterTemplate.mutate,
  onError: () => {
    makeTrpcErrorToast({ entity: $i18n.t('filters.template.entity'), mode: 'delete' })
    filterTemplateToDelete.value = undefined
  },
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['filterTemplates', 'getAll'] })
    makeTrpcSuccessToast({ entity: $i18n.t('filters.template.entity'), mode: 'delete' })
    filterTemplateToDelete.value = undefined
    selectedTemplateId.value = null
  },
})
</script>

<template>
  <div class="hidden md:block p-5 bg-white rounded">
    <n-collapse :trigger-areas="['main', 'arrow']" :default-expanded-names="countFilters(filter) > 0 ? ['filters'] : []">
      <n-collapse-item name="filters">
        <template #header>
          <h4>
            {{ $t('filters.title', { count: countFilters(filter) }) }}
          </h4>
        </template>
        <template v-if="filterTemplates" #header-extra>
          <n-input-group class="hidden lg:flex">
            <n-select
              v-model:value="selectedTemplateId"
              class="min-w-48 lg:min-w-96"
              :options="templateOptions"
              :placeholder="$t('filters.template.select')"
              :render-option="({ node, option }) => showOptionLabelOnHover({
                node: addOptionDeleteButton({ node, option }, (s: string) => { filterTemplateToDelete = s }),
                option,
              })"
            />
            <n-button secondary :disabled="!selectedTemplateId" @click="applyFilterTemplate">
              {{ $t('filters.template.apply') }}
            </n-button>
          </n-input-group>
        </template>
        <div class="flex flex-col gap-2">
          <FiltersGroup
            v-if="filter"
            :filter-config="props.filterConfig"
            :applied-filter="filter"
            disable-group-removal
            applied-filter-path=""
            @set="setFilterValue"
            @unset="unsetFilterValue"
          />
          <div class="flex gap-2 w-full">
            <div class="flex-grow" />
            <n-button v-if="canUse('filter.template.create')" :disabled="isFilterEmpty" @click="saveFilterTemplate">
              {{ $t('filters.template.save') }}
            </n-button>
            <n-button :disabled="isFilterEmpty" @click="resetFilters">
              {{ $t('filters.reset') }}
            </n-button>
            <n-button type="primary" :disabled="isFilterEmitted" @click="emitFilter()">
              {{ $t('filters.apply') }}
              <template #icon>
                <Icon name="ph:funnel-simple-bold" />
              </template>
            </n-button>
          </div>
        </div>
      </n-collapse-item>
    </n-collapse>
  </div>
  <FiltersTemplateCreateOrUpdateContainer :opener="openFilterTemplatePopup" />
  <TheConfirmPopup
    v-if="filterTemplateToDelete"
    :show="Boolean(filterTemplateToDelete)"
    :text="$t('confirmation.delete.text', { entity: $t('filters.template.entity') })"
    :confirm-text="$t('button.delete')"
    @close="filterTemplateToDelete = undefined"
    @confirm="deleteFilterTemplate.mutate({ id: filterTemplateToDelete })"
  />
</template>
