<script lang="ts" setup>
import type { CreateFilterTemplate } from '~/types'

const props = defineProps<{ payload: CreateFilterTemplate, isLoading?: boolean }>()
const emit = defineEmits<{ (e: 'save', payload: CreateFilterTemplate): void, (e: 'cancel'): void }>()

const { cloned } = useCloned(props.payload)
</script>

<template>
  <FormKitForm
    v-model="cloned.data"
    :mode="payload.mode"
    @submit="emit('save', cloned)"
    @cancel="$emit('cancel')"
  >
    <FormKit
      name="name"
      type="text"
      :label="$t('filters.template.name.label')"
      :placeholder="$t('filters.template.name.placeholder')"
      validation="required"
    />
  </FormKitForm>
</template>
