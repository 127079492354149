import { NButton, NTooltip, type SelectOption } from 'naive-ui'

export const showOptionLabelOnHover = ({ node, option }: { node: VNode, option: SelectOption }) => {
  return h(NTooltip, { placement: 'left' }, {
    trigger: () => node,
    default: () => option.label,
  })
}

export const addOptionDeleteButton = ({ node, option }: { node: VNode, option: SelectOption }, callback: (s: string) => void) => {
  return h('div', { style: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', maxWidth: '100%' } }, [
    h('div', { style: { flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } }, [node]),
    h(NButton, { onClick: () => callback(option.value as string), size: 'tiny', class: 'p-4 mr-1', secondary: true }, useRenderIcon({ name: 'uil:trash', size: '14' })),
  ])
}
