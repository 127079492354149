import pointer from 'json-pointer'
import type { Filter } from './schema'
import type { FilterConfig } from './configs'
import { METHOD_TYPES } from './configs'

export const transformFiltersIntoPrismaFilters = (config: FilterConfig, filter: Filter, parentPath = '', initialObject: any = {}) => {
  if (filter.type === 'group') {
    for (const index in filter.filters) {
      const childFilter = filter.filters[index]
      const fullPath = `${parentPath}/${filter.conjunction}/${index}`
      transformFiltersIntoPrismaFilters(config, childFilter, fullPath, initialObject)
    }
  }
  if (filter.type === 'filter') {
    const fieldType = config[filter.path].methodType
    const filterMethods = METHOD_TYPES[fieldType]
    let fieldOptions
    try {
      // @ts-expect-error as we're try/catching it
      fieldOptions = filterMethods?.[filter.method]?.(filter.value)
    } catch (error) {
      throw new Error(`"${parentPath}": ${error}`)
    }
    if (!fieldOptions) {
      throw new Error(`"${parentPath}": is empty`)
    }
    if (Array.isArray(fieldOptions)) {
      fieldOptions.forEach((fieldOption) => {
        pointer.set(initialObject, `${parentPath}${filter.path}/${fieldOption.method}`, fieldOption.value)
      })
    } else {
      pointer.set(initialObject, `${parentPath}${filter.path}/${fieldOptions.method}`, fieldOptions.value)
    }
  }
  return initialObject
}

export const countFilters = (filter: Filter) => {
  const dictionary = pointer.dict(filter)
  const typeKeys = Object.keys(dictionary).filter(key => key.endsWith('/type'))
  const typeValues = typeKeys.map(key => dictionary[key])
  return typeValues.filter(typeValue => typeValue === 'filter').length
}
