import type { FilterGroup, FilterProperty } from './schema'

export const EMPTY_FILTER_PROPERTY: FilterProperty = {
  type: 'filter',
  path: '',
  method: '',
  value: '',
}

export const EMPTY_FILTER_GROUP: FilterGroup = {
  type: 'group',
  conjunction: 'AND',
  filters: [],
}
