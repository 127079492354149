<script lang="ts" setup>
import type { FilterConfig } from './configs'
import type { FilterGroup } from './schema'
import { CONJUNCTION_TYPES } from './configs'
import { EMPTY_FILTER_GROUP, EMPTY_FILTER_PROPERTY } from './defaults'
import CloseIcon from '~/assets/icons/digital/close.svg'

const props = defineProps<{
  appliedFilter: FilterGroup
  appliedFilterPath: string
  filterConfig: FilterConfig
  disableGroupRemoval?: boolean
}>()

const emit = defineEmits<{
  (e: 'set', appliedFilterPath: string, value: any): void
  (e: 'unset', appliedFilterPath: string): void
}>()

const { $i18n } = useNuxtApp()
const conjunctionOptions = CONJUNCTION_TYPES.map(conjunction => ({ value: conjunction, label: $i18n.t(`filters.conjunction.${conjunction}`) }))
</script>

<template>
  <div class="flex flex-col gap-2">
    <div class="flex gap-2">
      <n-select
        :value="appliedFilter.conjunction ?? CONJUNCTION_TYPES[0]"
        :options="conjunctionOptions"
        class="w-36"
        @update:value="emit('set', `${appliedFilterPath}/conjunction`, $event)"
      />
      <n-button-group>
        <n-button tertiary @click="emit('set', `${appliedFilterPath}/filters/${appliedFilter.filters.length}`, EMPTY_FILTER_PROPERTY)">
          {{ $t('filters.add.filter') }}
          <template #icon>
            <Icon name="mdi:funnel-plus" />
          </template>
        </n-button>
        <n-button tertiary @click="emit('set', `${appliedFilterPath}/filters/${appliedFilter.filters.length}`, EMPTY_FILTER_GROUP)">
          {{ $t('filters.add.group') }}
          <template #icon>
            <Icon name="material-symbols:account-tree-rounded" />
          </template>
        </n-button>
      </n-button-group>
      <n-button circle quaternary :title="$t('filters.remove.group')" :disabled="!!props.disableGroupRemoval" @click="emit('unset', appliedFilterPath)">
        <div class="h-6 w-6">
          <CloseIcon />
        </div>
      </n-button>
    </div>
    <div class="flex flex-col gap-2 ml-8">
      <div v-for="filter, index of appliedFilter.filters" :key="index" class="flex gap-2">
        <template v-if="filter.type === 'filter'">
          <FiltersFilter
            :filter-config="props.filterConfig"
            :applied-filter="filter"
            :applied-filter-path="`${appliedFilterPath}/filters/${index}`"
            @set="(path, value) => emit('set', path, value)"
            @unset="(path) => emit('unset', path)"
          />
        </template>
        <template v-if="filter.type === 'group'">
          <FiltersGroup
            v-if="filter.filters"
            :applied-filter="filter"
            :applied-filter-path="`${appliedFilterPath}/filters/${index}`"
            :filter-config="props.filterConfig"
            @set="(path, value) => emit('set', path, value)"
            @unset="(path) => emit('unset', path)"
          />
        </template>
      </div>
    </div>
  </div>
</template>
